import React from 'react';
import dynamics from '../../Assets/svg/dynamics.svg';
import azure from '../../Assets/svg/azure.svg';
import linux from '../../Assets/svg/linux.svg';
import powerplatform from '../../Assets/svg/powerplatform.svg';

const Admin: React.FC<{ header: string }> = ({ header }) => {
    return (
        <div className='grid grid-cols-2 skills-span-stack-items'>
            <h4 className='col-span-2 skills-h4'>{header}</h4>
            <div className='icon'>
                <img className='img-tech' src={linux} alt="Azure" />
                <span>Linux</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={azure} alt="Azure" />
                <span>Azure</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={dynamics} alt="MS Dynamics 365" />
                <span>MS Dynamics 365</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={powerplatform} alt="Azure" />
                <span>Power Platform</span>
            </div>
        </div>
    );
};

export default Admin;