import React from 'react'
import Home from '../../Pages/Home/Home';
import { Route, Routes } from 'react-router-dom';
import About from '../../Pages/Skills/Skills';
import ROUTES from '../../utils/routes';
import Experience from '../../Pages/Experience/Experience';
import Projects from '../../Pages/Projects/Projects';
import NotFound from '../../Pages/NotFound/NotFound';
import Contact from '../../Pages/Contact/Contact';
import Policy from '../../Pages/Policy/Policy';
import Coming from '../../Pages/Coming/Coming';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTES.Home} element={<Home />} />
      <Route path={ROUTES.Skills} element={<About />} />
      <Route path={ROUTES.Projects} element={<Projects />} />
      <Route path={ROUTES.Experience} element={<Experience />} />
      <Route path={ROUTES.Contact} element={<Contact />} />
      <Route path={ROUTES.Policy} element={<Policy />} />
      {/* ------------------------------------------------------- */}
      <Route path={ROUTES.Coming} element={<Coming />} />
      <Route path={ROUTES.NotFound} element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
};

export default AppRoutes;
