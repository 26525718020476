const ROUTES = {
  Home: "/",
  Skills: "/skills",
  Experience: "/experience",
  Projects: "/projects",
  Contact: "/contact",
  Coming: "/coming",
  Policy: "/policy",
  NotFound: "/404",
};
export default ROUTES;
