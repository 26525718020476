import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../utils/routes';

const axiosInstance = axios.create({
    baseURL: "http://192.168.1.5",
});

const NotFoundInterceptor = ({ children }: any) => {
    const navigate = useNavigate();
    
    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            debugger;
            if (error.response.status === 404) {
                navigate(ROUTES.NotFound);
            } else {
                return Promise.reject(error)
            }
        }
    )
    return children;
};
export default NotFoundInterceptor;
