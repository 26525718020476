import React from "react";
import "./Home.css";
import {
  Avatar,
  Box,
  Button,
} from "@mui/material";
import Img from "../../Assets/me.png";
import { useLanguage } from "../../Providers/LanguageContext";
import SocialIcons from "../../Components/SocialIcons/SocialIcons";
import DownloadIcon from "../../Assets/svg/download.svg";
import ReactCountriesFlags from 'react-countries-flags';
import useServer from "../../Hooks/useServer";

const Home: React.FC = () => {
  const { translations } = useLanguage();
  const { GetCV } = useServer();
  const { Headers, Personal } = translations;
  if (!Headers || !Personal) {
    return null;
  }

  const getCV = async (lang: string) => {
    let response = await GetCV(lang);
    if (response && response.succeed) {
      // const data: BlobPart[] = response.data as BlobPart[];
      // const blob = new Blob(data);
      // const url = window.URL.createObjectURL(blob);
      // const link = document.createElement('a');
      // link.href = url;
    }

  }

  return (

    <div className="my-24">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        className="main-styles"
      >
        <Avatar
          alt="Picture of Przemek"
          src={Img}
          className="avatar"
        />

        <h1 className="text-name">
          {Personal.Name}
        </h1>

        <hr />
        <div className="text-position">
          {Personal.Position}
        </div>
        <hr />
        <div className="text-desc text-sm sm:text-sm md:textmd lg:text-lg xl:text-xl">
          {Personal.Description}
        </div>
        <div className="mb-4">
          <SocialIcons className="link w-16 h-16 hover:scale-125" withLang={false} />
        </div>
        <div className="cv-buttons-container">
          <Button
            onClick={() => getCV('pl')}
            className='cv-button'
          >
            <div className="grid grid-cols-2 gap-2">
              <img src={DownloadIcon} alt="get cv" />
              <ReactCountriesFlags isoCode='pl' />
            </div>
          </Button>
          <Button
            onClick={() => getCV('en')}
            className='cv-button'
          >
            <div className="grid grid-cols-2 gap-2">
              <img src={DownloadIcon} alt="get cv" />
              <ReactCountriesFlags isoCode='gb' />
            </div>
          </Button>
        </div>
      </Box>
    </div>

  );
};

export default Home;
