import React from 'react';
import csharp from '../../Assets/svg/csharp.svg';
import sql from '../../Assets/svg/sqlserver.svg';
import dotnet from '../../Assets/svg/dotnet.svg';
import efcore from '../../Assets/svg/efcore.svg';

const Backend: React.FC<{ header: string }> = ({ header }) => {
    return (
        <div className='grid grid-cols-2 skills-span-stack-items'>
            <h4 className='col-span-2 skills-h4'>{header}</h4>
            <div className='icon'>
                <img className='img-tech' src={csharp} alt="C#" />
                <span>C#</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={dotnet} alt=".NET" />
                <span>.NET</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={sql} alt="SQL Server" />
                <span>SQL Server</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={efcore} alt="Entity Framework Core" />
                <span>Entity Framework Core</span>
            </div>
        </div>
    );
};

export default Backend;