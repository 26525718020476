import React from 'react';
import './Coming.css';

const Coming: React.FC = () => {
    return (
        <div className="home grid grid-cols-1 grid-rows-12 md:grid-cols-3">
            <div className="md:col-start-2 row-start-4 row-span-4 m-4">
                <div className="coming-tile main-styles1 w-full p-8">
                    <h1 className='coming-h1'>Coming Soon</h1>
                    <p className='coming-p'>Work in progress</p>
                </div>
            </div>
        </div>
    );
};

export default Coming;