import React from 'react';
import './Navbar.css';
import ROUTES from '../../utils/routes';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../Providers/LanguageContext';
import menuIcon from '../../Assets/svg/menu.svg';
import SocialIcons from '../SocialIcons/SocialIcons';
import { useLocation } from 'react-router-dom';


const Navbar = ({ toggle }: { toggle: () => void }) => {
  const location = useLocation();
  const { translations } = useLanguage();
  const { NavbarNames } = translations;
  if (!NavbarNames) {
    return null;
  }
  console.log(location.pathname);
  return (
    <div className="w-full h-12 sm:h-12 md:h-12 lg:h-14 xl:h-20 rounded-b-xl text-xl font-extrabold nav">
      <div className="mx-auto h-full md:px-72">
        <div className="grid grid-cols-3 items-center h-full">
          <div className="md:hidden mx-auto">
            <button
              type="button"
              className="mb-4"
              onClick={toggle}
            >
              <img src={menuIcon} alt="menu" className='min-h-12 min-w-12' />
            </button>
          </div>
          <div className="hidden md:block text-white text-left links">
            <Link to={ROUTES.Home}>
              <p>{NavbarNames.Fullname}</p>
            </Link>
          </div>
          <div className='mx-auto'>
            <ul className="hidden md:flex gap-x-6 w-full text-white links">
              <li>
                <Link className={location.pathname === ROUTES.Skills ? "active" : "link "} to={ROUTES.Skills}>
                  <p>{NavbarNames.Skills}</p>
                </Link>
              </li>
              <li>
                <Link className={location.pathname === ROUTES.Experience ? "active" : "link "} to={ROUTES.Experience}>
                  <p>{NavbarNames.Experience}</p>
                </Link>
              </li>
              <li>
                <Link className={location.pathname === ROUTES.Projects ? "active" : "link "} to={ROUTES.Projects}>
                  <p>{NavbarNames.Projects}</p>
                </Link>
              </li>
              <li>
                <Link className={location.pathname === ROUTES.Contact ? "active" : "link "} to={ROUTES.Contact}>
                  <p>{NavbarNames.Contact}</p>
                </Link>
              </li>
            </ul>
          </div>
          <ul className='hidden md:flex text-white ml-auto link'>
            <li>
              <SocialIcons className='link w-7 h-7 hover:scale-125' />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
