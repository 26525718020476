import React from 'react';
import { useLanguage } from '../../Providers/LanguageContext';
import './Policy.css';

const Policy: React.FC = () => {
    const { translations } = useLanguage();
    const { Policy } = translations;
    if (!Policy) {
        return null;
    }

    return (
        <div className='policy-div p-8'>
            <h1>{Policy.Header}</h1>
            <p className='policy-desc'>{Policy.Description}</p>
            {Policy.Points.map((point: any, index: number) => (
                <div key={index}>
                    <h2>{(index + 1) + '. '}{point.Title}</h2>
                    <p className='mt-4'>{point.Content}</p>
                </div>
            ))}
            <div className='text-right'>
                <span>{Policy.LastUpdated}</span>
            </div>
        </div>
    );
};

export default Policy;