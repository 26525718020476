import axios, { AxiosError } from "axios";
import { ISendEmailReq } from "../Models/Requests/SendEmailReq"
import { IAxiosResponse } from "../Models/Responses/IAxiosResponse";
import { IServerResponse } from "../Models/IServerResponse";
export const API_URL =
    process.env.NODE_ENV === 'production'
        ? 'https://pkuczynski.dev/api'
        : 'https://localhost:44326/api';

const axiosAuth = axios.create({
    baseURL: API_URL,
});

const useServer = () => {
    const SendEmail = async (req: ISendEmailReq): Promise<IServerResponse> => {
        try {
            const response = await axiosAuth.post("/Email/SendEmail", req);
            const rawData = response.data;
            return { succeed: true, data: rawData, message: rawData.message };
        } catch (error) {
            const err = error as AxiosError<IAxiosResponse>;
            return {
                succeed: false,
                message:
                    err.response !== undefined
                        ? err.response.data.message
                        : 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
            };
        }
    };

    const GetCV = async (isoCode: string): Promise<IServerResponse> => {
        try {
            const response = await axiosAuth.get("/Sftp/GetCV?isoCode=" + isoCode,
                {
                    responseType: 'arraybuffer',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'CV Przemysław Kuczyński ' + isoCode.toUpperCase() + '.pdf');
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            const rawData = response.data;
            return { succeed: true, message: rawData.message };
        } catch (error) {
            const err = error as AxiosError<IAxiosResponse>;
            return {
                succeed: false,
                message:
                    err.response !== undefined
                        ? err.response.data.message
                        : 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
            };
        }
    };

    const GetData = (): IServerResponse => {
        try {
            axiosAuth.get("/Data/GetData");

            return { succeed: true, message: 'Pobrano dane' };
        } catch (error) {
            const err = error as AxiosError<IAxiosResponse>;
            return {
                succeed: false,
                message:
                    err.response !== undefined
                        ? err.response.data.message
                        : 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
            };
        }
    };
    return { SendEmail, GetCV, GetData };
}

export default useServer;