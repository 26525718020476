import React, { createContext, useState, useContext } from "react";
import translationsData from "../DB/translations.json";
import { LanguageContextType, LanguageData, PolicyPrivacy, Project } from "./Interfaces";

const LanguageContext = createContext({} as LanguageContextType);

export const getDataForLanguage = (language: string): LanguageData => {
    if (!translationsData) {
        console.error('Dane tłumaczeń są niedostępne.');
        return {
            Personal: {}, Contact: {}, Headers: {},
            NavbarNames: {}, Experience: { Jobs: [] }, Projects: { Projects: [] }, 
            Policy: { } as PolicyPrivacy, ValidationMessages: {}, SkillsCategories: {},
        };
    }
    const { Personal, Contact, Headers, NavbarNames, Experience, Projects, Policy, ValidationMessages, SkillsCategories } = translationsData;
    const languageData: LanguageData = {
        Personal: Personal[language as keyof typeof Personal] || {},
        Contact: Contact[language as keyof typeof Contact] || {},
        Headers: Headers[language as keyof typeof Headers] || {},
        NavbarNames: NavbarNames[language as keyof typeof NavbarNames] || {},
        Experience: Experience[language as keyof typeof Experience] || [],
        Projects: Projects[language as keyof typeof Projects] || [] as Project[],
        ValidationMessages: ValidationMessages[language as keyof typeof ValidationMessages] || {},
        SkillsCategories: SkillsCategories[language as keyof typeof SkillsCategories] || {},
        Policy: Policy[language as keyof typeof Policy] || {},
    };
    return languageData;
};

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [selectedLanguage, setSelectedLanguage] = useState("pl");
    const [translations, setTranslations] = useState<any>(getDataForLanguage(selectedLanguage));

    const handleLanguageChange = (language: string) => {
        setSelectedLanguage(language);
        setTranslations(getDataForLanguage(language));
    };

    return (
        <LanguageContext.Provider
            value={{ selectedLanguage, translations, handleLanguageChange }}
        >
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error("useLanguage must be used within a LanguageProvider");
    }
    return context;
};
