import React, { useState } from 'react';
import { Box } from "@mui/material";
import votereum from '../../Assets/votereum.png';
import movies from '../../Assets/movies.png';
import github from '../../Assets/github.png';
import courier from '../../Assets/courier.png';
import './Projects.css';
import { useLanguage } from '../../Providers/LanguageContext';

const Projects: React.FC = () => {
    const [imageList] = useState<any[]>([votereum, courier, movies, github]);
    const { translations } = useLanguage();
    const { Projects, Headers } = translations;
    if (!Projects || !Headers) {
        return null;
    }
    return (
        <div className='mb-24'>
            <h1 className="header-projects">
                {Headers.Projects}
            </h1>
            <div className='px-8'>
                {Projects.Projects.map((project: any, index: number) => {
                    return (
                        <div key={index} className={`project-main main-styles grid grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-1 ${index % 2 === 1 ? 'right-slide' : ''}`}>
                            <Box
                                component={"img"}
                                src={imageList[index]}
                                className={`project-img rounded-lg row-start-1 col-span-1${index % 2 === 1 ? 'md:col-start-3' : 'md:col-start-1'}`}
                            />
                            <div className={`col-span-2 p-4 row-start-2 row-span-2 md:row-start-1 ${index % 2 === 1 ? 'md:col-start-1' : 'md:col-start-2'}`}>
                                <h2 className="project-name">
                                    {project.Title}
                                </h2>
                                <p className="project-desc">
                                    {project.Description}
                                </p>
                                <div className='project-span'>
                                    <span className="project-tech">
                                        {project.TechnologieName}
                                    </span><br />
                                    <div dangerouslySetInnerHTML={{ __html: project.Technologies }} />
                                    <span className="project-link">
                                        <div dangerouslySetInnerHTML={{ __html: project.Link }} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Projects;