import React from 'react';
import vs22 from '../../Assets/svg/vs22.svg';
import vscode from '../../Assets/svg/vscode.svg';
import postman from '../../Assets/svg/postman.svg';
import figma from '../../Assets/svg/figma.svg';
import git from '../../Assets/svg/git.svg';
import github from '../../Assets/svg/github.svg';
import gitlab from '../../Assets/svg/gitlab.svg';

const Tools: React.FC<{ header: string }> = ({ header }) => {
    return (
        <div className='grid grid-cols-2 skills-span-stack-items'>
            <h4 className='col-span-2 skills-h4'>{header}</h4>
            <div className='icon'>
                <img className='img-tech' src={vs22} alt="Visual Studio 22" />
                <span>Visual Studio 22</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={vscode} alt="Visual Studio Code" />
                <span>Visual Studio Code</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={git} alt="Git" />
                <span>GIT</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={github} alt="Github" />
                <span>Github</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={gitlab} alt="GitLab" />
                <span>GitLab</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={postman} alt="Postman" />
                <span>Postman</span>
            </div>
            <div className='icon col-span-2'>
                <img className='img-tech' src={figma} alt="Figma" />
                <span>Figma</span>
            </div>
        </div>
    );
};

export default Tools;