import { Link } from "react-router-dom";
import { useLanguage } from "../../Providers/LanguageContext";
import ROUTES from "../../utils/routes";
import "../Navbar/Navbar.css";
import "./Footer.css";

const Footer = () => {
  const { translations } = useLanguage();
  const { Policy } = translations;
  if (!Policy) {
    return null;
  }
  return (
    <footer className="w-full h-10 sm:h-12 md:h-14 lg:h-16 xl:h-24 text-xs md:text-base lg:text-lg xl:text-xl 2xl:text-xl rounded-t-xl text-white text-center p-1 sm:p-2 md:p-2 lg:p-3 xl:p-5 foot">
      <span>
        &copy; 2024 Przemysław Kuczyński. All Rights Reserved
      </span>
      <div>
        <Link to={ROUTES.Policy} className="link-policy" >{Policy.Header}</Link>
      </div>
    </footer>
  );
};

export default Footer;
