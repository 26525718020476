import { Link } from 'react-router-dom';
import { useLanguage } from '../../../Providers/LanguageContext';
import ReactCountriesFlags from 'react-countries-flags';
import { Button } from '@mui/material';
import ROUTES from '../../../utils/routes';
import closeIcon from '../../../Assets/svg/close.svg';
import './Sidebar.css';
import '../Navbar.css';

const Sidebar = ({
    isOpen,
    toggle,
}: {
    isOpen: boolean;
    toggle: () => void;
}): JSX.Element => {
    const { selectedLanguage, handleLanguageChange, translations } = useLanguage();

    const { NavbarNames } = translations;
    return (
        <>
            <div
                className="sidebar-container fixed w-40 h-full overflow-hidden justify-center grid pt-[120px] left-0 z-10"
                style={{
                    opacity: `${isOpen ? "1" : "0"}`,
                    top: ` ${isOpen ? "0" : "-100%"}`,
                }}
            >
                <button className="absolute left-0 p-5" onClick={toggle}>
                    <img src={closeIcon} alt="close" />
                </button>

                <ul className="sidebar-nav text-center leading-relaxed text-xl">
                    <li>
                        <Link className="link" to={ROUTES.Home} onClick={toggle}>
                            <p>{NavbarNames.Home}</p>
                        </Link>
                    </li>
                    <li>
                        <Link className="link" to={ROUTES.Skills} onClick={toggle}>
                            <p>{NavbarNames.Skills}</p>
                        </Link>
                    </li>
                    <li>
                        <Link className="link" to={ROUTES.Experience} onClick={toggle}>
                            <p>{NavbarNames.Experience}</p>
                        </Link>
                    </li>
                    <li>
                        <Link className="link" to={ROUTES.Projects} onClick={toggle}>
                            <p>{NavbarNames.Projects}</p>
                        </Link>
                    </li>
                    <li>
                        <Link className="link" to={ROUTES.Contact} onClick={toggle}>
                            <p>{NavbarNames.Contact}</p>
                        </Link>
                    </li>
                    <li>
                        <Button
                            onClick={() => handleLanguageChange(selectedLanguage === 'pl' ? 'en' : 'pl')}
                            className='langButton'>
                            {selectedLanguage === 'pl' ? <ReactCountriesFlags isoCode='pl' /> : <ReactCountriesFlags isoCode='gb' />}
                        </Button>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Sidebar;