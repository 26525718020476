import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

const NotFound: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="home py-32 md:p-56">
            <div className="md:col-start-2 row-start-4 row-span-5 m-4">
                <div className="skills-tile main-styles w-full p-8">
                    <h1 className='notfound-h1'>404 - Page Not Found</h1>
                    <p className='notfound-p'>The page you are looking for does not exist.</p>
                    <Button variant="contained" color="primary" className="submit-button" onClick={() => navigate(-1)}>Back</Button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;