import React from 'react';
import './Skills.css';
import { useLanguage } from '../../Providers/LanguageContext';
import Frontend from '../../Components/SkillsCategories/Frontend';
import Backend from '../../Components/SkillsCategories/Backend';
import Admin from '../../Components/SkillsCategories/Admin';
import Tools from '../../Components/SkillsCategories/Tools';
import IconsHidden from '../../Components/SkillsCategories/IconsHidden';


const Skills: React.FC = () => {
  const { translations } = useLanguage();
  const { Headers, Personal, SkillsCategories } = translations;
  if (!Headers || !Personal || !SkillsCategories) {
    return null;
  }
  return (
    <div className='h-full'>
      <div className="main-div">
        <div className="skills-tile main-styles right-slide w-full p-8">
          <h1 className="skills-h1">{Headers.Skills}</h1>
          <div className="md:grid grid-cols-2">
            <Frontend header={SkillsCategories.Frontend} />
            <Backend header={SkillsCategories.Backend}/>
            <Admin header={SkillsCategories.Administration}/>
            <Tools header={SkillsCategories.Tools}/>
            <IconsHidden />
          </div>
        </div>
      </div >
    </div>
  );
};

export default Skills;
