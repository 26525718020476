import React from "react";
import { Link } from "react-router-dom";
import githubIcon from "../../Assets/svg/github.svg";
import linkedinIcon from "../../Assets/svg/linkedin.svg";
import { useLanguage } from "../../Providers/LanguageContext";
import ReactCountriesFlags from 'react-countries-flags';
import { Button } from '@mui/material';

interface SocialIconsProps {
    className?: string;
    withLang?: boolean;
}

const SocialIcons: React.FC<SocialIconsProps> = ({ className, withLang = true }) => {
    const { selectedLanguage, handleLanguageChange } = useLanguage();
    return (
        <div className="flex gap-x-6 text-white items-center">
            <Link to="https://linkedin.com/in/przemysław-kuczyński-575155131" target="_blank" rel="noopener noreferrer">
                <img src={linkedinIcon} alt="linkedin" className={className != null ? className : ''} />
            </Link>
            <Link to="https://github.com/przemek978" target="_blank" rel="noopener noreferrer">
                <img src={githubIcon} alt="github" className={className != null ? className : ''} />
            </Link>
            {withLang &&
                <Button
                    onClick={() => handleLanguageChange(selectedLanguage === 'pl' ? 'en' : 'pl')}
                    className='langButton w-7 h-7 hover:scale-125 p-2'
                >
                    {selectedLanguage === 'pl' ? <ReactCountriesFlags isoCode='pl' /> : <ReactCountriesFlags isoCode='gb' />}
                </Button>
            }
        </div>
    );
};

export default SocialIcons;
