import React, { useState } from 'react';
import './Contact.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import { useLanguage } from '../../Providers/LanguageContext';
import mail from '../../Assets/svg/mail.svg';
import useServer from '../../Hooks/useServer';
import { ISendEmailReq } from '../../Models/Requests/SendEmailReq';

const Contact: React.FC = () => {
    const [message, setMessage] = useState('');
    const [isSent, setIsSent] = useState(false);
    const { translations } = useLanguage();
    const { Headers, Contact, ValidationMessages } = translations;
    const { SendEmail } = useServer();

    if (!Headers || !Contact || !ValidationMessages) {
        return null;
    }

    const handleSubmit = async (values: ISendEmailReq, resetForm: any) => {
        let response = await SendEmail(values);
        if (response) {
            setMessage(response.message);
            if (response.succeed) {
                setIsSent(true);
                resetForm();
            } else {
                setIsSent(false);
            }
        }
    };

    const validationSchema = Yup.object().shape({
        Name: Yup.string()
            .min(3, ValidationMessages.NameMinLength)
            .required(ValidationMessages.Name),
        Email: Yup.string()
            .required(ValidationMessages.Email),
        Body: Yup.string()
            .required(ValidationMessages.Body),
        Subject: Yup.string()
            .required(ValidationMessages.Subject)
    });
    return (
        <div className="p-12">
            <div className="contact main-styles-contact max-w-[100vw] md:max-w-[40vw] mx-auto">
                <div className="contact-tile">
                    <h1 className='contact-h1'>{Headers.Contact}</h1>
                    <p className='contact-p'>{Contact.Info}
                        <a href='mailto:contact@pkuczynski.dev'>
                            <img src={mail} alt='mailto' className='mail-icon' />
                        </a> {Contact.Info1}
                    </p>
                    <Formik
                        initialValues={{
                            Name: '',
                            Email: '',
                            Subject: '',
                            Body: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values: ISendEmailReq, { resetForm }) => handleSubmit(values, resetForm)}
                    >
                        <Form>
                            <div className="mx-4 form-container">
                                <div className='grid grid-cols-2 gap-2'>
                                    <div className="Name">
                                        <label htmlFor="Name">{Contact.Name}</label>
                                        <Field
                                            type="text"
                                            name="Name"
                                            className="input-text"
                                        />
                                        <ErrorMessage
                                            name="Name"
                                            component="div"
                                            className="error-message"
                                        />
                                    </div>
                                    <div className="Email">
                                        <label htmlFor="Email">{Contact.Email}</label>
                                        <Field
                                            type="email"
                                            name="Email"
                                            className="input-text"
                                        />
                                        <ErrorMessage
                                            name="Email"
                                            component="div"
                                            className="error-message"
                                        />
                                    </div>
                                </div>
                                <div className="Subject">
                                    <label htmlFor="Subject">{Contact.Subject}</label>
                                    <Field
                                        type="text"
                                        name="Subject"
                                        className="input-text"
                                    >
                                    </Field>
                                    <ErrorMessage
                                        name="Subject"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                                <div className="Body">
                                    <label htmlFor="Body">{Contact.Description}</label>
                                    <Field
                                        name="Body"
                                        className="input-text textarea"
                                        as="textarea"
                                    />
                                    <ErrorMessage
                                        name="Body"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                                {message && (
                                    <div className={isSent ? 'succed-message' : 'error-message'}>
                                        {message}
                                    </div>
                                )}
                                <div className="buttons-div">
                                    <Button variant='contained' color='primary' type="submit" className="submit-button">
                                        {Contact.Send}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div >


    );
};

export default Contact;