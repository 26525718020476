import { useLanguage } from '../../Providers/LanguageContext';
import edu from '../../Assets/svg/edu.svg';
import work from '../../Assets/svg/work.svg';
import './Experience.css';

const Experience = () => {
    const { translations } = useLanguage();
    const { Headers, Experience } = translations;
    if (!Headers || !Experience) {
        return null;
    }
    return (
        <div className='mb-24 mt-10'>
            <div className="">
                <div className="exp-tile main-styles w-full max-w-[100vw] md:max-w-[70vw] mx-auto">
                    <h1 className="exp-h1">{Headers.Experience}</h1>
                    <div className="timeline m-auto ml-4 md:m-auto">
                        {Experience.Jobs.map((job: any, index: any) => (
                            <div key={index} className="timeline-event">
                                <span className='timeline-date ml-[10vw] md:ml-[2vw] '>{job.Date}</span>
                                <div className="timeline-dot">
                                    <img src={job.Type === 'Education' ? edu : work} alt="edu" className='m-auto my-[0.5em]'  />
                                </div>
                                <div className="timeline-content p-4 md:w-[40vw]">
                                    <h3 className='exp-h3'>{job.Title}</h3>
                                    <p>{job.Company}</p>
                                    <p>{job.Description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;