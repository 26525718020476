import React from 'react';
import solidity from '../../Assets/svg/solidity.svg';
import polygon from '../../Assets/svg/polygon.svg';
import video from '../../Assets/svg/video.svg';

const IconsHidden: React.FC = () => {
    return (
        <div className='hidden'>
            <div className='icon'>
                <img className='img-tech' src={solidity} alt="Solidity" />
                <span>Solidity</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={polygon} alt="Polygon" />
                <span>Polygon</span>
            </div>
            <div className='icon'>
                <img className='img-tech' src={video} alt="Video" />
                <span>Video</span>
            </div>
        </div>
    );
};

export default IconsHidden;