import React from 'react';
import angular from '../../Assets/svg/angular.svg';
import css from '../../Assets/svg/css.svg';
import html from '../../Assets/svg/html.svg';
import javascript from '../../Assets/svg/js.svg';
import react from '../../Assets/svg/react.svg';
import typescript from '../../Assets/svg/ts.svg';
import wpf from '../../Assets/svg/wpf.svg';
import tailwind from '../../Assets/svg/tailwind.svg';

const Frontend: React.FC<{ header: string }> = ({ header }) => {
    return (
        <div className='grid grid-cols-2 skills-span-stack-items'>
              <h4 className='col-span-2 skills-h4'>{header}</h4>
              <div className='icon'>
                <img className='img-tech' src={html} alt="HTML" />
                <span>HTML</span>
              </div>
              <div className='icon'>
                <img className='img-tech' src={css} alt="CSS" />
                <span>CSS</span>
              </div>
              <div className='icon'>
                <img className='img-tech' src={javascript} alt="JavaScript" />
                <span>JavaScript</span>
              </div>
              <div className='icon'>
                <img className='img-tech' src={typescript} alt="TypeScript" />
                <span>TypeScript</span>
              </div>
              <div className='icon'>
                <img className='img-tech' src={react} alt="React" />
                <span>React</span>
              </div>
              <div className='icon'>
                <img className='img-tech' src={angular} alt="Angular" />
                <span>Angular</span>
              </div>
              <div className='icon'>
                <img className='img-tech' src={tailwind} alt="Tailwind" />
                <span>Tailwind</span>
              </div>
              <div className='icon'>
                <img className='img-tech' src={wpf} alt="WPF" />
                <span>WPF</span>
              </div>
            </div>
    );
};

export default Frontend;